/*#map{*/
/*    padding: 0;*/
/*    margin-top: 4%;*/
/*    height: 93vh;*/
/*}*/

/*#panel {*/
/*    width: 200px;*/
/*    font-family: Arial, sans-serif;*/
/*    font-size: 13px;*/
/*    float: right;*/
/*    margin: 10px;*/
/*}*/
/*#color-palette {*/
/*    clear: both;*/
/*}*/
/*.color-button {*/
/*    width: 14px;*/
/*    height: 14px;*/
/*    font-size: 0;*/
/*    margin: 2px;*/
/*    float: left;*/
/*    cursor: pointer;*/
/*}*/
/*#delete-button {*/
/*    margin-top: 5px;*/
/*}*/

/*#map-canvas {*/
/*    height: 100%;*/
/*    margin: 0px;*/
/*    padding: 0px*/
/*}*/
/*.controls {*/
/*    margin-top: 16px;*/
/*    border: 1px solid transparent;*/
/*    border-radius: 2px 0 0 2px;*/
/*    box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    height: 32px;*/
/*    outline: none;*/
/*    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);*/
/*}*/

/*#pac-input {*/
/*    background-color: #fff;*/
/*    font-family: Roboto;*/
/*    font-size: 15px;*/
/*    font-weight: 300;*/
/*    margin-left: 12px;*/
/*    padding: 0 11px 0 13px;*/
/*    text-overflow: ellipsis;*/
/*    width: 400px;*/
/*}*/

/*#pac-input:focus {*/
/*    border-color: #4d90fe;*/
/*}*/

/*.pac-container {*/
/*    font-family: Roboto;*/
/*}*/

/*#type-selector {*/
/*    color: #fff;*/
/*    background-color: #4d90fe;*/
/*    padding: 5px 11px 0px 11px;*/
/*}*/

/*#type-selector label {*/
/*    font-family: Roboto;*/
/*    font-size: 13px;*/
/*    font-weight: 300;*/
/*}*/

/*@media screen and (max-width: 2560px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 4%;*/
/*        height: 89vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1440px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 5%;*/
/*        height: 90vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1024px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 7%;*/
/*        height: 90vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 9%;*/
/*        height: 90vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 425px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 11%;*/
/*        height: 94vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 375px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 14%;*/
/*        height: 93vh;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 320px) {*/
/*    #map{*/
/*        padding: 0;*/
/*        margin-top: 16%;*/
/*        height: 100vh;*/
/*    }*/
/*}*/

/*noinspection ALL*/
#map-canvas {
    height: 92vh;
    margin: 0;
    padding: 0;
}

.controls {
    margin-top: 16px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#pac-input {
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 300px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#type-selector {
    color: #fff;
    background-color: #4d90fe;
    padding: 5px 11px 0 11px;
}

#type-selector label {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 300;
}

.pac-container {
    z-index: 10000 !important;
    font-family: Roboto, sans-serif;
    border-radius: 2px;
    line-height: 25px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.pac-container {
    font-family: Roboto, sans-serif;
}
