*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

/*body {*/
/*    font: normal 16px/1.5 "Helvetica Neue", sans-serif;*/
/*    background: #456990;*/
/*    color: #fff;*/
/*}*/

/* .section SECTION
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.section {
    background: #f45b69;
    padding: 50px 0;
}

.section .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.section h1 {
    font-size: 2.5rem;
}

.section h2 {
    font-size: 1.3rem;
}

/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline {
    white-space: nowrap;
    /*overflow-x: scroll;*/
}

.timeline p {
    color: #12161b;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.05px;
}

.timeline ol {
    font-size: 0;
    width: 100vw;
    padding: 8% 0;
    transition: all 1s;
    /*overflow-x: scroll;*/
}

.timeline ol li {
    position: relative;
    display: inline-block;
    list-style-type: none;
    width: 160px;
    height: 3px;
    background: #00000085;
}

.timeline ol li:last-child {
    width: 280px;
}

.timeline ol li:not(:first-child) {
    margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(100% + 1px);
    bottom: 0;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    border-radius: 50%;
    /*background: #F45B69;*/
    background: #0866db;
}

.timeline ol li div {
    position: absolute;
    left: calc(100% + 7px);
    width: 280px;
    padding: 15px;
    font-size: 1rem;
    white-space: normal;
    color: #12161b;
    background: #b9d4f44a;
}

.timeline ol li div::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.timeline ol li:nth-child(odd) div {
    top: -16px;
    transform: translateY(-100%);
}

.timeline ol li:nth-child(odd) div::before {
    top: 100%;
    border-width: 8px 8px 0 0;
    border-color: #b9d4f44a transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
    top: calc(100% + 16px);
}

.timeline ol li:nth-child(even) div::before {
    top: -8px;
    border-width: 8px 0 0 8px;
    border-color: transparent transparent transparent #b9d4f44a;
}

.timeline time {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

/* TIMELINE ARROWS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .disabled {
    opacity: 0.5;
}

.timeline .arrows img {
    width: 45px;
    height: 45px;
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 599px) {
    .timeline ol,
    .timeline ol li {
        width: auto;
    }

    .timeline ol {
        padding: 0;
        transform: none !important;
    }

    .timeline ol li {
        display: block;
        height: auto;
        background: transparent;
    }

    .timeline ol li:first-child {
        margin-top: 25px;
    }

    .timeline ol li:not(:first-child) {
        margin-left: auto;
    }

    .timeline ol li div {
        width: 94%;
        height: auto !important;
        margin: 0 auto 25px;
    }

    .timeline ol li div {
        position: static;
    }

    .timeline ol li:nth-child(odd) div {
        transform: none;
    }

    .timeline ol li:nth-child(odd) div::before,
    .timeline ol li:nth-child(even) div::before {
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        border: none;
        border-left: 1px solid white;
        height: 25px;
    }
}
