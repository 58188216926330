#map {
    height: 86vh;
}

/*!* Optional: Makes the sample page fill the window. *!*/
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
